<template>
    <app-dialog-info
        class="app-dialog-product-control-panel"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">SIM Control panel</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="form">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="form-group">
                    <div class="form-controls">
                        <app-switch v-model="settings.SIMEnabled">SIM Enabled</app-switch>
                    </div>

                    <div class="form-controls">
                        <app-switch v-model="settings.VMBoxRedirection">VM Box redirection</app-switch>
                    </div>
                </div>

                <button class="btn btn-primary btn-confirm" @click="confirm" :disabled="!is_changed">Confirm</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appSwitch from '@/components/app-switch'
import appInput from '@/components/app-input'

import appDialogInfo from '@/components/app-dialog-info'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true, },
    },

    components: {
        appLoader,
        appError,

        appSwitch,
        appInput,

        appDialogInfo,
    },

    data() {
        return {
            settings: {
                SIMEnabled: true,
                VMBoxRedirection: false,
            },
            original_settings: {
                SIMEnabled: true,
                VMBoxRedirection: false,
            },

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            /**
             * @todo
             */
        },

        confirm() {
            this.loading = true

            const delay_in_ms = 500

            setTimeout(() => {
                this.errors.show = false
                this.errors.message = ''

                this.$emit('confirm')
                this.loading = false
            }, delay_in_ms)
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            let is_changed = false

            for (const key in this.settings) {
                is_changed = this.settings[key] != this.original_settings[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-control-panel {
    .app-dialog-body {
        .form {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;

            .app-error {
                margin-bottom: 24px;
            }

            .form-group {
                width: 100%;

                margin-bottom: 40px;

                .form-controls {
                    margin-bottom: 30px;

                    .app-switch {
                        width: 100%;
                        justify-content: space-between;
                    }

                    .app-input {
                        width: auto;
                        flex-grow: 1;
                        min-width: 180px;
                    }

                    .form-sub-controls {
                        padding: 0 0 0 16px;

                        &>* {
                            margin: 8px 0 0;
                        }
                    }

                    .form-line-controls {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;

                        h5 {
                            padding: 2px 0;
                        }
                    }

                    h5 {
                        &.heading {
                            margin-right: 16px;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .btn-confirm {
                max-width: 210px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-control-panel {
        .app-dialog-body {
            .form {
                .form-group {
                    .form-controls {
                        margin-bottom: 16px;
                        
                        .form-sub-controls {
                            &>* {
                                margin: 4px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>