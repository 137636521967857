<template>
    <app-dialog-info
        class="app-dialog-product-number-port"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Create number port</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <div class="form-group">
                <div class="form-controls">
                    <app-radio-buttons
                        v-model="porting_type"
                        :items="porting_options"

                        label="Porting number in from:"

                        :inline="true"

                        @select="errors.message = null"
                    />
                </div>

                <div class="form-controls">
                    <!-- MSISDN -->
                    <app-input
                        v-model="port.MSISDN"

                        label="MSISDN"

                        :required="true"

                        :error="errors.fields.MSISDN"

                        @change="errors.fields.MSISDN = null"
                    />
                </div>

                <div class="form-controls">
                    <!-- LSP -->
                    <app-dropdown-select
                        v-model="port.LSP"
                        :options="lsps_list"

                        key-value="value"
                        key-title="title"

                        label="Losing Service Provider"

                        :error="errors.fields.LSP"

                        @change="errors.fields.LSP = null"
                    />
                </div>
            </div>

            <div class="form-group" v-if="porting_type == 'postpaymobile'">
                <div class="form-controls">
                    <!-- AccountNumber -->
                    <app-input
                        v-model="port.AccountNumber"

                        label="Account number"

                        :required="true"

                        :error="errors.fields.AccountNumber"

                        @change="errors.fields.AccountNumber = null"
                    />
                </div>

                <div class="form-controls">
                    <!-- CustomerName -->
                    <app-input
                        v-model="port.CustomerName"

                        label="Customer name"

                        :required="true"

                        :error="errors.fields.CustomerName"

                        @change="errors.fields.CustomerName = null"
                    />
                </div>
            </div>

            <div class="form-group" v-else>
                <div class="form-controls">
                    <!-- OldSIMID -->
                    <app-input
                        v-model="port.OldSIMID"

                        label="SIM Card ID"

                        :required="true"

                        :error="errors.fields.OldSIMID"

                        @change="errors.fields.OldSIMID = null"
                    />
                </div>
            </div>

            <div class="btns">
                <button class="btn btn-primary" @click="submit">Submit</button>
                <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'

import NumberPortValidator from '@/validators/number-port-validator'
import errMessage from '@/helpers/errMessage'

const PRODUCT_TYPES = [
    'postpaymobile',
    'prepaymobile',
]

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true },

        productType: { required: true, validator: value => PRODUCT_TYPES.indexOf(value) !== -1 },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
        appRadioButtons,
        appDropdownSelect,
    },

    data() {
        return {
            port: {
                MSISDN: null,
                LSP: null,

                AccountNumber: null,
                CustomerName: null,

                OldSIMID: null,
            },

            lsps: [],

            porting_type: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.porting_type = this.productType

            this.getLosingServiceProviders()
        },

        getLosingServiceProviders() {
            this.loading = true

            const params = {
                // SPID: this.main_spid,
                // SPID: this.current_spid,

                PhoneNumber: this.product.MSISDN,
            }

            this.$store.dispatch('api_lmnpproto/ServiceProviderLookupSP', params)
                .then(({ IPMSSPID, ServiceProviderList }) => {
                    this.lsps = ServiceProviderList

                    const findServiceProvider = service_provider => service_provider.ID == IPMSSPID

                    if (this.lsps.findIndex(findServiceProvider) != -1) {
                        this.port.LSP = IPMSSPID
                    }

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.lsps = []

                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },

        close() {
            this.$emit('close')
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            let fields = {
                MSISDN: { rule: 'MSISDN', message: 'Please enter valid MSISDN' },
                LSP: { rule: 'LSP', message: 'Please select losing service provider' },
            }

            if (this.porting_type == 'postpaymobile') {
                fields = {
                    ...fields,

                    AccountNumber: { rule: 'AccountNumber', message: 'Please enter valid account number' },
                    CustomerName: { rule: 'CustomerName', message: 'Please enter valid customer name' },
                }
            } else {
                fields = {
                    ...fields,

                    OldSIMID: { rule: 'OldSIMID', message: 'Please enter valid SIM Card ID' },
                }
            }

            for (const key in fields) {
                if (NumberPortValidator.isRuleExists(fields[key].rule)) {
                    if (NumberPortValidator.isInvalid(fields[key].rule, this.port[key], fields[key].message)) {
                        this.errors.fields[key] = NumberPortValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        submit() {
            if (this.validation()) {
                this.loading = true

                let action = 'api_postpay/PostpayProductPortRequest'

                let params = {
                    SPID: this.current_spid,

                    CustomerUUID: this.$route.params.uuid,
                    UUID: this.product.UUID,

                    MSISDN: this.port.MSISDN,
                    LSP: +this.port.LSP,
                }

                if (this.porting_type == 'postpaymobile') {
                    // Postpay Product Port Request

                    params = {
                        ...params,

                        AccountNumber: this.port.AccountNumber,
                        CustomerName: this.port.CustomerName,
                    }
                } else {
                    // Prepay Product Port Request

                    action = 'api_prepay/PrepayProductPortRequest'

                    params = {
                        ...params,

                        OldSIMID: this.port.OldSIMID,
                    }
                }

                this.$store.dispatch(action, params)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.$emit('confirm')
                        this.loading = false
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'spid',

            'current_spid',
        ]),

        main_spid() {
            return +this.spid
        },

        lsps_list() {
            return this.lsps.map(service_provider => ({
                value: service_provider.ID,
                title: service_provider.Name,
            }))
        },

        porting_options() {
            return [
                {
                    value: 'postpaymobile',
                    label: 'Postpay',
                },
                {
                    value: 'prepaymobile',
                    label: 'Prepay',
                },
            ]
        },

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-number-port {
    .app-dialog-body {
        .form-group {
            width: 100%;

            margin-bottom: 24px;

            .form-controls {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btns {
            margin-top: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-number-port {
        .app-dialog-body {
            .btns {
                padding: 0;
            }
        }
    }
}
</style>