<template>
    <app-dialog-info
        class="app-dialog-product-plan-change"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Plan change</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="content">
                    <app-dropdown-select
                        v-model="ProductAvailabilityUUID"
                        :options="plans"

                        key-value="UUID"
                        key-title="Title"

                        label="Plan"
                    />
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" :disabled="!is_changed" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true, },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appDropdownSelect,
    },

    data() {
        return {
            plans: {},

            ProductAvailabilityUUID: null,

            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.ProductAvailabilityUUID = this.product.Plan.UUID

            this.getPlans()
        },

        getPlans() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                ProductInstanceUUID: this.product.UUID,
            }

            console.log('params', params)

            this.$store.dispatch('api_postpay/FindPostpayMobilePlansPaginated', params)
                .then(({ PostpayMobilePlans }) => {
                    this.plans = Array.isArray(PostpayMobilePlans) ? PostpayMobilePlans
                        .map(plan => ({
                            ...plan,
                            Title: `${ plan.Name } ($${ plan.Cents % 100 == 0 ? plan.Cents / 100 : (plan.Cents / 100).toFixed(2) })`,
                        }))
                        .reduce((obj, item) => {
                            obj[item.UUID] = { ...item }
                            return obj
                        }, {}) : {}

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.plans = {}

                    this.loading = false
                })
        },

        close() {
            this.$emit('close')
        },

        confirm() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUID: this.product.UUID,
                CustomerUUID: this.$route.params.uuid,
                PlanUUID: this.ProductAvailabilityUUID,
            }

            this.$store.dispatch('api_postpay/ChangePlan', params)
                .then(() => {
                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.$emit('confirm')
                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            return this.ProductAvailabilityUUID != this.product.Plan.UUID
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-plan-change {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-plan-change {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>