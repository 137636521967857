<template>
    <div class="page-customer-products-fibre">
        <app-loader v-if="loading" fixed-on="desktop" />

        <app-error v-model="errors.show" :message="errors.message" />

        <app-table
            class="products-fibre-table"

            :sort-value="filter.sort"
            :sort-options="sort"
            @sort-change="onSortChange"

            :cols="cols"
            :rows="rows"
        />

        <app-pagination
            v-if="pagination.total"

            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        />

        <app-adding-banner-service service="Fibre" :tablet-mode="true" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBannerService from '@/components/app-adding-banner-service'

import formatDateNano from '@/helpers/format-date-nano'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
        appAddingBannerService,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'UUID',           title: 'Service UUID' },
                { field: 'Address',        title: 'Address'      },
                { field: 'State',          title: 'State'        },
                { field: 'CreatedAtNanos', title: 'Date'         },
            ],

            cols: [
                { key: 'uuid',    title: 'Service UUID', sort: { field: 'UUID'           }, highlight: true },
                { key: 'address', title: 'Address',      sort: { field: 'Address'        }                  },
                { key: 'state',   title: 'State',        sort: { field: 'State'          }                  },
                { key: 'date',    title: 'Date',         sort: { field: 'CreatedAtNanos' }                  },
            ],

            products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getProducts()
        },

        getProducts(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                CustomerUUID: this.customer_uuid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_ufbprod/GetUFBProductsPaginated', params)
                .then(({ UFBProducts, PageInfo }) => {
                    this.products = UFBProducts

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 404) {
                        // this.errors.show = true
                        // this.errors.message = "No fibre products found"
                    } else {
                        this.errors.show = true
                        this.errors.message = errMessage(error)
                    }

                    this.products = []

                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        customer_uuid() {
            return this.$route.params.uuid
        },

        rows() {
            return Array.isArray(this.products)
                ? this.products.map(product => ({
                    uuid:    product.UUID,
                    address: product.Address,
                    state:   product.State,
                    date:    product.CreatedAtNanos ? formatDateNano(product.CreatedAtNanos) : '?',
                }))
                : []
        },
    },
}
</script>

<style lang="scss">
.page-customer-products-fibre {
    .app-error {
        margin-bottom: 24px;
    }

    .products-fibre-table {
        @include table-cols-width((330px, 330px, 150px, 150px), true);
    }

    .app-pagination,
    .app-adding-banner-service {
        margin-top: 30px;
    }
}

@media (max-width: $mobile-size) {
    .page-customer-products-fibre {
        .products-fibre-table {
            @include table-cols-width-mobile((90px, 184px), true);
        }

        .app-pagination,
        .app-adding-banner-service {
            margin-top: 24px;
        }
    }
}
</style>