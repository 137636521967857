<template>
    <app-dialog-info
        class="app-dialog-product-sim-swap"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">SIM swap</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="content">
                    <app-input
                        v-model="SIMID"

                        label="SIM Card ID"
                        placeholder="Please enter the 16 or 19 digit SIM Card ID"

                        :required="true"

                        :mask="simid_mask"

                        :error="errors.fields.SIMID"

                        @change="onSIMIDChange"
                    />
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm" :disabled="!is_changed || !is_sim_card_id_validation_successful">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import MobileServiceValidator from '@/validators/mobile-service-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true, },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            SIMID: null,

            is_sim_card_id_validation_successful: false,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.SIMID = this.product.SIMID
        },

        close() {
            this.$emit('close')
        },

        onSIMIDChange() {
            const field = {
                rule: 'IMSISimCardID',
                value: this.SIMID,
                message: 'Please enter the 16 or 19 digit SIM Card ID',
            }

            if (MobileServiceValidator.isInvalid(field.rule, field.value, field.message)) {
                this.errors.fields.SIMID = MobileServiceValidator.getError()
            } else {
                this.$delete(this.errors.fields, 'SIMID')

                this.validateSIMCardID()
            }
        },

        validateSIMCardID() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                SIMID: this.SIMID,
            }

            this.$store.dispatch('api_simdb/FindSIMSPaginated', params)
                .then(({ SIMS }) => {
                    this.errors.show = false
                    this.errors.message = ''

                    if (SIMS.length == 0) {
                        this.is_sim_card_id_validation_successful = false

                        this.errors.show = true
                        this.errors.message = 'That SIMID is not available'
                    } else {
                        const sim = SIMS[0]

                        if (sim.Active) {
                            this.is_sim_card_id_validation_successful = false

                            this.errors.show = true
                            this.errors.message = `That SIMID is already in use by service ${ sim.ServiceInstanceUUID }`
                        } else {
                            this.is_sim_card_id_validation_successful = true
                        }
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.is_sim_card_id_validation_successful = false

                    this.loading = false
                })
        },

        confirm() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUID: this.product.UUID,
                SIMID: this.SIMID,
            }

            console.log('params', params)

            this.$store.dispatch('api_postpay/UpdatePostpayProduct', params)
                .then(response => {
                    this.errors.show = false
                    this.errors.message = ''

                    this.$emit('confirm')
                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        simid_mask() {
            return {
                mask: '9999999999999999999',
                showMaskOnHover: false,
            }
        },

        is_changed() {
            return this.SIMID != this.product.SIMID
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-sim-swap {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-sim-swap {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>