<template>
    <app-dialog-info
        class="app-dialog-product-sim-swap"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">SIM swap</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="content">
                    <app-input
                        v-model="SIMID"

                        label="SIMID"

                        :error="errors.fields.SIMID"
                    />
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm" :disabled="!is_changed">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import FixedWirelessValidator from '@/validators/fixed-wireless-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true, },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            SIMID: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {
                    SIMID: null,
                },
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.SIMID = this.product.SIMID
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = [
                {
                    rule: 'simid',
                    message: 'Your SIMID is invalid',
                    value: this.SIMID,
                    key: 'SIMID',
                },
            ]

            for(let field of fields) {
                if (FixedWirelessValidator.isRuleExists(field.rule)) {
                    if (FixedWirelessValidator.isInvalid(field.rule, field.value, field.message)) {
                        this.errors.fields[ field.key ] = FixedWirelessValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        close() {
            this.$emit('close')
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.product.UUID,
                    SIMID: this.SIMID,
                }

                console.log('params', params)

                this.$store.dispatch('api_fwa/UpdateFWAProduct', params)
                    .then(response => {
                        this.errors = {
                            show: false,
                            message: '',
                        }

                        this.$emit('confirm')
                        this.loading = false
                    })
                    .catch(error => {
                        this.errors = {
                            show: true,
                            message: errMessage(error),

                            fields: {
                                SIMID: null,
                            },
                        }

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            return this.SIMID != this.product.SIMID
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-sim-swap {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-sim-swap {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>