<template>
    <app-dialog-info
        class="app-dialog-product-change-msisdn"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Change MSISDN</h2>
        </template>

        <template #body>
            <app-loader v-if="loading || processing" />

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="content">
                    <app-input
                        :value="product.MSISDN"

                        label="Current MSISDN"

                        :readonly="true"
                    />

                    <app-input
                        v-model="MSISDN"

                        label="New MSISDN"

                        :required="true"

                        :error="errors.fields.MSISDN"

                        @change="errors.fields.MSISDN = null"
                    />

                    <app-dropdown-select
                        v-model="type"
                        :options="options_type"

                        key-value="value"
                        key-title="title"

                        label="Change type"
                    />
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left"             @click="confirm" :disabled="!is_changed">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import MobileServiceValidator from '@/validators/mobile-service-validator'
import errMessage from '@/helpers/errMessage'

const DEFAULT_TYPE = 'mobile'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        product: { required: true, },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            MSISDN: null,
            type: DEFAULT_TYPE,

            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                MSISDN: { rule: 'MSISDN', message: 'Please, enter valid MSISDN' },
            }

            for (const key in fields) {
                if (MobileServiceValidator.isRuleExists(fields[key].rule)) {
                    if (MobileServiceValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = MobileServiceValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.product.UUID,
                    CustomerUUID: this.$route.params.uuid,
                    MSISDN: this.MSISDN,
                }

                console.log('params', params)

                this.$store.dispatch('api_postpay/ChangeMSISDN', params)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.$emit('confirm')

                        this.loading = false
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            return (this.MSISDN && this.MSISDN != this.product.MSISDN)
                || this.type != DEFAULT_TYPE
        },

        options_type() {
            return [
                {
                    value: 'FWA',
                    title: 'FWA',
                },
                {
                    value: DEFAULT_TYPE,
                    title: 'mobile',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-product-change-msisdn {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;

            & > * {
                margin-top: 24px;

                &:first-child {
                    margin: 0;
                }
            }
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-product-change-msisdn {
        .app-dialog-body {
            .content {
                margin: 0;

                & > * {
                    margin-top: 16px;
                }
            }
        }
    }
}
</style>