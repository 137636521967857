<template>
    <div class="app-mobile-product" :class="{ 'with-plan': with_plan }">
        <div class="header">
            <h3 class="heading">{{ heading }}</h3>
            <div class="actions">
                <app-actions
                    :actions="actions_list"
                    :disabled="is_actions_disabled"

                    @click="onAction"
                ></app-actions>
            </div>
        </div>

        <div class="product-body">
            <div class="main-info">
                <img src="./mobile-product.png">

                <div class="details-list">
                    <div class="detail">
                        <span class="title">IMSI:</span>
                        <span class="value">{{ product.IMSI }}</span>
                    </div>

                    <div class="detail">
                        <span class="title">MSISDN:</span>
                        <span class="value">{{ product.MSISDN }}</span>

                        <div class="actions" v-if="with_primary_actions">
                            <app-actions
                                :actions="primary_actions_list"
                                :disabled="is_primary_actions_disabled"

                                @click="onAction"
                            ></app-actions>
                        </div>
                    </div>

                    <div class="detail">
                        <span class="title">State:</span>
                        <span class="value">{{ product.State }}</span>
                    </div>

                    <div class="detail">
                        <span class="title">SIM ID:</span>
                        <span class="value">{{ product.SIMID }}</span>

                        <div class="actions" v-if="with_secondary_actions">
                            <app-actions
                                :actions="secondary_actions_list"
                                :disabled="is_secondary_actions_disabled"

                                @click="onAction"
                            ></app-actions>
                        </div>
                    </div>

                    <template v-if="false">
                        <div v-if="testWarnings" class="warning">SIM is disabled</div>
                        <div v-if="testWarnings" class="warning">VM Box redirection is disabled</div>
                    </template>
                </div>
            </div>

            <div class="plan" v-if="with_plan">
                <h4 class="heading">Plan:</h4>

                <div class="content">
                    <div class="column">
                        <div class="title">Description</div>
                        <div class="value">{{ product.Plan.Description }}</div>
                    </div>

                    <div class="column">
                        <div class="title">Price</div>
                        <div class="value">${{ product.Plan.Price }} / {{ product.Plan.Period }}</div>
                    </div>
                </div>

                <div class="content">
                    <div class="column">
                        <div class="title">Instance UUID</div>
                    </div>
                    <div class="column">
                        <div class="value">{{ product.UUID }}</div>
                    </div>
                </div>

                <template v-if="showAddress">
                    <h4 class="heading">Address:</h4>

                    <div class="content">
                        <div class="column">
                            <div class="value">{{ product.ServiceAddress }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import appActions from '@/components/app-actions'

const ACTIONS = {
    'supplementary-services': { action: 'supplementary-services', title: 'Supp Services', icon: 'actions' },
    'sim-swap':               { action: 'sim-swap',               title: 'SIM Swap'                       },
    'plan-change':            { action: 'plan-change',            title: 'Plan Change'                    },
    'change-msisdn':          { action: 'change-msisdn',          title: 'Change MSISDN'                  },
    'control-panel':          { action: 'control-panel',          title: 'SIM Control panel'                  },
    'cancel':                 { action: 'cancel',                 title: 'Cancel',        primary: true   },
    'number-port':            { action: 'number-port',            title: 'Create number port'             },
}

export default {
    props: {
        data: { required: true },

        actions:          { type: Array, required: true  },
        primaryActions:   { type: Array, required: false },
        secondaryActions: { type: Array, required: false },

        showAddress: { type: Boolean, default: true },

        testWarnings: { type: Boolean, default: false },
    },

    components: {
        appActions,
    },

    methods: {
        onAction(btn) {
            this.$emit(btn.action, btn)
            this.$emit('action', btn)
        },
    },

    computed: {
        with_plan() {
            return Boolean(this.data.Plan)
        },

        heading() {
            return this.with_plan ? this.product.Plan.Name : '?'
        },

        product() {
            return {
                UUID: this.data.UUID,

                IMSI: this.data.IMSI ? this.data.IMSI : '?',
                MSISDN: this.data.MSISDN ? this.data.MSISDN : '?',

                State: this.data.State ? this.data.State : '?',
                SIMID: this.data.SIMID ? this.data.SIMID : '?',

                ServiceAddress: this.data.ServiceAddress ? this.data.ServiceAddress : '?',

                Plan: this.with_plan ? {
                    Name: this.data.Plan.Name,
                    Description: this.data.Plan.Description,
                    Price: this.data.Plan.Cents % 100 == 0 ? this.data.Plan.Cents / 100 : (this.data.Plan.Cents / 100).toFixed(2),
                    Period: 'month',
                } : null
            }
        },

        is_actions_disabled() {
            // return false
            return !(this.actions_list.length && this.data.State && this.data.State.toLowerCase() == 'active')
        },
        actions_list() {
            let actions_list = []

            for (let i = 0, len = this.actions.length; i < len; i++) {
                const action = this.actions[i]

                if (action in ACTIONS) {
                    actions_list.push(ACTIONS[action])
                }
            }

            return actions_list
        },


        is_primary_actions_disabled() {
            return !(this.primary_actions_list.length && this.data.State && this.data.State.toLowerCase() == 'active')
        },
        primary_actions_list() {
            let primary_actions_list = []

            for (let i = 0, len = this.primaryActions.length; i < len; i++) {
                const action = this.primaryActions[i]

                if (action in ACTIONS) {
                    primary_actions_list.push(ACTIONS[action])
                }
            }

            return primary_actions_list
        },
        with_primary_actions() {
            return Array.isArray(this.primaryActions) && this.primaryActions.length > 0
        },

        is_secondary_actions_disabled() {
            return !(this.secondary_actions_list.length && this.data.State && this.data.State.toLowerCase() == 'active')
        },
        secondary_actions_list() {
            let secondary_actions_list = []

            for (let i = 0, len = this.secondaryActions.length; i < len; i++) {
                const action = this.secondaryActions[i]

                if (action in ACTIONS) {
                    secondary_actions_list.push(ACTIONS[action])
                }
            }

            return secondary_actions_list
        },
        with_secondary_actions() {
            return Array.isArray(this.secondaryActions) && this.secondaryActions.length > 0
        },
    },
}
</script>

<style lang="scss">

$small-tablet-size: 767px;

.app-mobile-product {
    $picture-width: 130px;

    position: relative;

    padding: 16px 0;

    .header {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 24px;

        .actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            margin-left: auto;

            .btn {
                display: flex;

                width: auto;
                height: 24px;

                // margin-right: 16px;

                font-size: 18px;

                &.action-cancel {
                    &::before {
                        width: 24px;
                        height: 24px;

                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .product-body {
        display: flex;

        .main-info {
            display: flex;

            width: 100%;

            font-size: 16px;
            line-height: 24px;

            img {
                display: block;

                width: $picture-width;
                height: $picture-width;

                object-fit: cover;

                border-radius: $border-radius-primary;
            }

            .details-list {
                width: calc(100% - #{ $picture-width });

                padding: 5px 24px;

                .detail {
                    display: flex;
                    flex-wrap: wrap;

                    margin-bottom: 8px;

                    .title {
                        margin-right: 8px;
                    }

                    .value {
                        color: var(--color-table-secondary);
                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;

                        margin-left: auto;

                        .btn {
                            display: flex;

                            width: auto;
                            height: 24px;

                            font-size: 18px;

                            &.action-cancel {
                                &::before {
                                    width: 24px;
                                    height: 24px;

                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .warning {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    color: var(--color-error);
                    line-height: 24px;

                    @include icon-before($icon-attention);
                }
            }
        }

        .plan {
            width: 100%;

            padding-left: 24px;

            h4 {
                &.heading {
                    margin-bottom: 16px;

                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                }
            }

            .content {
                display: flex;
                justify-content: space-between;

                width: 100%;

                margin-bottom: 16px;
                padding: 8px 16px;

                background-color: var(--color-collapse-bg);

                border-radius: $border-radius-primary;

                .column {
                    margin-right: 24px;

                    .title {
                        margin-bottom: 2px;
                    }

                    .value {
                        color: var(--color-table-secondary);
                    }

                    &:last-child {
                        flex-shrink: 0;
                        margin-right: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.with-plan {
        .product-body {
            .main-info {
                // max-width: calc(min(45%, 390px));
                max-width: calc(min(50%, 400px));

                border-right: 1px solid var(--color-input-border);
            }
        }
    }

    &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        display: block;
        height: 1px;

        content: '';

        background-color: var(--color-divider);
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-mobile-product {
        $picture-width: 120px;

        .header {
            margin-bottom: 16px;
        }

        .product-body {
            .main-info {
                font-size: 14px;

                img {
                    width: $picture-width;
                    height: $picture-width;
                }

                .details-list {
                    width: calc(100% - #{ $picture-width });

                    padding: 0 16px;
                }
            }

            .plan {
                padding-left: 16px;

                h4 {
                    &.heading {
                        margin-bottom: 12px;
                    }
                }

                .content {
                    margin-bottom: 12px;
                    padding: 8px 8px 4px;

                    .column {
                        margin-right: 10px;

                        .title {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $small-tablet-size) {
    .app-mobile-product {
        $picture-width: 108px;

        padding: 8px 0;

        .header {
            h3 {
                &.heading {
                    font-size: 18px;
                }
            }

            .actions {
                .btn {
                    font-size: 16px;

                    span {
                        display: none;
                    }
                }
            }
        }

        .product-body {
            flex-direction: column;

            .main-info {
                position: relative;

                img {
                    width: $picture-width;
                    height: $picture-width;
                }

                .details-list {
                    width: calc(100% - #{ $picture-width });

                    .detail {
                        margin-bottom: 4px;

                        .actions {
                            .btn {
                                font-size: 16px;

                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .plan {
                padding: 8px 0 0 0;

                h4 {
                    &.heading {
                        margin-bottom: 8px;

                        font-size: 16px;
                    }
                }

                .content {
                    flex-direction: column;

                    margin-bottom: 8px;
                    padding: 8px;

                    .column {
                        margin: 0 0 8px 0;

                        &:last-child {
                            display: flex;

                            margin-bottom: 0;

                            .title {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }

        &.with-plan {
            .product-body {
                .main-info {
                    max-width: 100%;

                    padding-bottom: 8px;

                    border-right: none;

                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        display: block;
                        height: 1px;

                        content: '';

                        background-color: var(--color-input-border);
                    }
                }
            }
        }

        &::after {
            background-color: var(--color-input-border);
        }
    }
}

@media (max-width: $mobile-size) {}
</style>