<template>
    <div class="app-mobile-products-list" :class="{ 'has-no-one-product': has_no_one_product }">
        <app-loader v-if="loading" />

        <h2 class="heading">{{ header }}</h2>

        <div class="mobile-products-list">
            <app-mobile-product v-for="(product, index) in products" :key="index"
                :data="product"

                :test-warnings="!index && testWarnings"

                :actions="actions"
                :primary-actions="primaryActions"
                :secondary-actions="secondaryActions"

                :show-address="showAddress"

                @action="onAction($event.action, product)"
            />
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'

import appMobileProduct from './components/app-mobile-product'

export default {
    props: {
        loading:  { type: Boolean, default: false             },
        header:   { type: String,  default: 'Mobile products' },
        products: {                required: true             },

        actions:          { type: Array, required: true  },
        primaryActions:   { type: Array, required: false },
        secondaryActions: { type: Array, required: false },

        showAddress: { type: Boolean, default: true },

        testWarnings: { type: Boolean, default: false },
    },

    components: {
        appLoader,

        appMobileProduct,
    },

    methods: {
        onAction(action, product) {
            this.$emit(action, product)
        },
    },

    computed: {
        has_no_one_product() {
            return Array.isArray(this.products) ? this.products.length == 0 : Object.keys(this.products).length == 0
        },
    },
}
</script>

<style lang="scss">
.app-mobile-products-list {
    position: relative;

    width: 100%;

    padding: 24px 24px 0;

    border-radius: $border-radius-secondary;
    background-color: var(--color-component-bg-primary);
    box-shadow: var(--box-shadow-secondary);

    h2 {
        &.heading {
            margin-bottom: 8px;
        }
    }

    &.has-no-one-product {
        padding-bottom: 16px;
    }
}

@media (max-width: $tablet-size) {
    .app-mobile-products-list {
        padding: 24px 16px 0;
    }
}

@media (max-width: $mobile-size) {
    .app-mobile-products-list {
        padding: 16px 8px 0;

        &.has-no-one-product {
            padding-bottom: 8px;
        }
    }
}
</style>