<template>
    <div class="page-customer-products">
        <div class="row">
            <div class="col-12">
                <app-tabs v-if="show_tabs" v-model="current_tab" :tabs="tabs" :dropdownOnTablet="true" />

                <template v-if="have_at_least_one_tab">
                    <component v-for="(tab, index) in tabs" :key="index" :is="tab.body" v-show="current_tab.id == tab.id" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTabs from '@/components/app-tabs'

import Fibre from './fibre'
import FWA from './fwa'
import PostPayMobile from './postpay-mobile'
import PrePayMobile from './prepay-mobile'

export default {
    components: {
        appTabs,
    },

    computed: {
        ...mapGetters([
            'modules_user',
            'is_prerelease_mode',
        ]),

        current_tab: {
            get() {
                // if (this.have_at_least_one_tab) {}

                let tab = null

                if (this.$route.params.type) {
                    tab = this.tabs.find(tab => this.$route.params.type == tab.id)
                }

                if (!tab) {
                    tab = {
                        ...this.tabs[0],
                        replace: true,
                    }
                }

                return tab
            },

            set(tab) {
                this.$router.push({
                    name: 'customer-products',
                    params: {
                        type: tab.id,
                    },
                    replace: true,
                })
            },
        },

        tabs() {
            const tabs = [
                ...(this.modules_user.PROD_FIBRE ? [
                    {
                        id:    'fibre',
                        title: 'Fibre Broadband',
                        body: Fibre,
                    },
                ] : []),

                ...(this.modules_user.PROD_FWA ? [
                    {
                        id:    'fwa',
                        title: 'Fixed Wireless',
                        body: FWA,
                    },
                ] : []),

                ...(this.modules_user.PROD_MOBILE ? [
                    {
                        id:    'postpay-mobile',
                        title: 'PostPay Mobile',
                        body: PostPayMobile,
                    },
                ] : []),

                ...(this.modules_user.PREPAY_MOBILE && this.is_prerelease_mode ? [
                    {
                        id:    'prepay-mobile',
                        title: 'PrePay Mobile',
                        body: PrePayMobile,
                    },
                ] : []),
            ]

            return tabs
        },

        have_at_least_one_tab() {
            return this.tabs.length > 0
        },

        show_tabs() {
            return this.tabs.length > 1
        },
    },
}
</script>

<style lang="scss">
// .page-customer-products {}
</style>