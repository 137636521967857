<template>
    <div class="app-actions">
        <button class="btn btn-table-action action-primary action-more" :disabled="disabled" @click="toggle"></button>

        <div class="app-actions-list" v-show="opened">
            <button v-for="(btn, key) in actions" :key="key"
                class="btn btn-table-action"
                :class="[
                    `action-${ btn.primary ? 'primary' : 'secondary' }`,
                    `action-${ btn.icon ? btn.icon : btn.action }`,
                ]"

                :disabled="disabled || btn.disabled"

                @click="onActionClick(btn)"
            >{{ btn.title }}</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        actions: { type: Array, required: true },
        disabled: { type: Boolean, default: false },
    },

    data() {
        return {
            opened: false,
        }
    },

    methods: {
        onActionClick(btn) {
            this.$emit(btn.action, btn)
            this.$emit('click', btn)

            this.close()
        },

        open() {
            this.opened = true
            document.addEventListener('click', this.handleClickOutside)
        },

        close() {
            this.opened = false
            document.removeEventListener('click', this.handleClickOutside)
        },

        toggle() {
            if (this.opened) {
                this.close()
            } else {
                this.open()
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside)
    },
}
</script>

<style lang="scss">
.app-actions {
    position: relative;

    .app-actions-list {
        position: absolute;
        right: 0;
        min-width: 180px;
        margin-top: 8px;
        padding: 12px 0;
        font-size: 18px;
        border-radius: $border-radius-secondary;
        background-color: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-primary);
        z-index: $z-index-dropdown;

        .btn {
            margin: 12px 0 0;
            padding: 0 24px 0 20px;

            white-space: nowrap;

            &:first-child { margin: 0; }

            &:before {
                margin-right: 4px;
                font-size: inherit;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-actions {
        .app-actions-list {
            font-size: 16px;
        }
    }
}
</style>