<template>
    <div class="page-customer-products-postpay-mobile">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="row">
            <div class="col-tab-12" :class="[ products_list_class ]">
                <app-error v-model="errors.show" :message="errors.message" />

                <app-mobile-products-list
                    header="PostPay Mobile Products"

                    :products="rows"

                    :show-address="false"
                    :test-warnings="true"

                    :actions="[
                        'supplementary-services',
                        // 'sim-swap',
                        'plan-change',
                        'cancel',
                    ]"

                    :primary-actions="[
                        'change-msisdn',
                        'number-port',
                    ]"

                    :secondary-actions="[
                        'control-panel',
                    ]"

                    @supplementary-services="settingsChange"
                    @sim-swap="simSwap"
                    @plan-change="planChange"
                    @change-msisdn="changeMSISDN"
                    @control-panel="controlPanel"
                    @cancel="cancel"
                    @number-port="numberPort"
                />

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>

            <div class="col-tab-12" :class="[ adding_banner_class ]">
                <app-adding-banner-service service="PostPay Mobile" />
            </div>
        </div>

        <app-dialog-product-sim-swap
            v-if="dialogs.product.sim_swap.show"
            v-model="dialogs.product.sim_swap.show"

            :product="dialogs.product.sim_swap.product"

            @confirm="onDialogProductSimSwapConfirm"
            @close="onDialogProductSimSwapClose"
        />

        <app-dialog-product-settings-change
            v-if="dialogs.product.settings_change.show"
            v-model="dialogs.product.settings_change.show"

            :product="dialogs.product.settings_change.product"

            @confirm="onDialogProductSettingsChangeConfirm"
            @close="onDialogProductSettingsChangeClose"
        />

        <app-dialog-product-plan-change
            v-if="dialogs.product.plan_change.show"
            v-model="dialogs.product.plan_change.show"

            :product="dialogs.product.plan_change.product"

            @confirm="onDialogProductPlanChangeConfirm"
            @close="onDialogProductPlanChangeClose"
        />

        <app-dialog-product-removal
            v-if="dialogs.product.remove.show"
            v-model="dialogs.product.remove.show"

            :uuid="dialogs.product.remove.uuid"

            @confirm="onDialogProductRemovalConfirm"
            @close="onDialogProductRemovalClose"
        />

        <app-dialog-product-change-msisdn
            v-if="dialogs.product.change_msisdn.show"
            v-model="dialogs.product.change_msisdn.show"

            :product="dialogs.product.change_msisdn.product"

            @confirm="onDialogProductChangeMSISDNConfirm"
            @close="onDialogProductChangeMSISDNClose"
        />

        <app-dialog-product-control-panel
            v-if="dialogs.product.control_panel.show"
            v-model="dialogs.product.control_panel.show"

            :product="dialogs.product.control_panel.product"

            @confirm="onDialogProductControlPanelConfirm"
            @close="onDialogProductControlPanelClose"
        />

        <app-dialog-product-number-port
            v-if="dialogs.product.number_port.show"
            v-model="dialogs.product.number_port.show"

            :product="dialogs.product.number_port.product"

            product-type="postpaymobile"

            @confirm="onDialogProductNumberPortConfirm"
            @close="onDialogProductNumberPortClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appPagination from '@/components/app-pagination'
import appMobileProductsList from '@/components/app-mobile-products-list'
import appAddingBannerService from '@/components/app-adding-banner-service'

import appDialogProductSimSwap from './components/app-dialog-product-sim-swap'
import appDialogProductSettingsChange from './components/app-dialog-product-settings-change'
import appDialogProductPlanChange from './components/app-dialog-product-plan-change'
import appDialogProductRemoval from './components/app-dialog-product-removal'
import appDialogProductChangeMsisdn from './components/app-dialog-product-change-msisdn'
import appDialogProductControlPanel from './components/app-dialog-product-control-panel'
import appDialogProductNumberPort from '../components/app-dialog-product-number-port'

import formatDateNano from '@/helpers/format-date-nano'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appPagination,
        appMobileProductsList,
        appAddingBannerService,

        appDialogProductSimSwap,
        appDialogProductSettingsChange,
        appDialogProductPlanChange,
        appDialogProductRemoval,
        appDialogProductChangeMsisdn,
        appDialogProductControlPanel,
        appDialogProductNumberPort,
    },

    data() {
        return {
            plans: {},

            products: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                product: {
                    sim_swap: {
                        show: false,
                        product: null,
                    },

                    settings_change: {
                        show: false,
                        product: null,
                    },

                    plan_change: {
                        show: false,
                        product: null,
                    },

                    remove: {
                        show: false,
                        uuid: null,
                    },

                    change_msisdn: {
                        show: false,
                        product: null,
                    },

                    control_panel: {
                        show: false,
                        product: null,
                    },

                    number_port: {
                        show: false,
                        product: null,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },

            large_tablet_mode: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        simSwap(product) {
            this.dialogs.product.sim_swap.show = true
            this.dialogs.product.sim_swap.product = product
        },
        onDialogProductSimSwapConfirm() {
            this.getProducts()

            this.dialogs.product.sim_swap.show = false
            this.dialogs.product.sim_swap.product = null
        },
        onDialogProductSimSwapClose() {
            this.dialogs.product.sim_swap.show = false
            this.dialogs.product.sim_swap.product = null
        },

        settingsChange(product) {
            this.dialogs.product.settings_change.show = true
            this.dialogs.product.settings_change.product = product
        },
        onDialogProductSettingsChangeConfirm() {
            this.getProducts()

            this.dialogs.product.settings_change.show = false
            this.dialogs.product.settings_change.product = null
        },
        onDialogProductSettingsChangeClose() {
            this.dialogs.product.settings_change.show = false
            this.dialogs.product.settings_change.product = null
        },

        planChange(product) {
            this.dialogs.product.plan_change.show = true
            this.dialogs.product.plan_change.product = product
        },
        onDialogProductPlanChangeConfirm() {
            this.getProducts()

            this.dialogs.product.plan_change.show = false
            this.dialogs.product.plan_change.product = null
        },
        onDialogProductPlanChangeClose() {
            this.dialogs.product.plan_change.show = false
            this.dialogs.product.plan_change.product = null
        },

        changeMSISDN(product) {
            this.dialogs.product.change_msisdn.show = true
            this.dialogs.product.change_msisdn.product = product
        },
        onDialogProductChangeMSISDNConfirm() {
            this.getProducts()

            this.dialogs.product.change_msisdn.show = false
            this.dialogs.product.change_msisdn.product = null
        },
        onDialogProductChangeMSISDNClose() {
            this.dialogs.product.change_msisdn.show = false
            this.dialogs.product.change_msisdn.product = null
        },

        controlPanel(product) {
            this.dialogs.product.control_panel.show = true
            this.dialogs.product.control_panel.product = product
        },
        onDialogProductControlPanelConfirm() {
            this.getProducts()

            this.dialogs.product.control_panel.show = false
            this.dialogs.product.control_panel.product = null
        },
        onDialogProductControlPanelClose() {
            this.dialogs.product.control_panel.show = false
            this.dialogs.product.control_panel.product = null
        },

        cancel(product) {
            this.dialogs.product.remove.show = true
            this.dialogs.product.remove.uuid = product.UUID
        },
        onDialogProductRemovalConfirm() {
            this.getProducts()

            this.dialogs.product.remove.show = false
            this.dialogs.product.remove.uuid = null
        },
        onDialogProductRemovalClose() {
            this.dialogs.product.remove.show = false
            this.dialogs.product.remove.uuid = null
        },

        numberPort(product) {
            this.dialogs.product.number_port.show = true
            this.dialogs.product.number_port.product = product
        },
        onDialogProductNumberPortConfirm() {
            this.getProducts()

            this.dialogs.product.number_port.show = false
            this.dialogs.product.number_port.product = null
        },
        onDialogProductNumberPortClose() {
            this.dialogs.product.number_port.show = false
            this.dialogs.product.number_port.product = null
        },

        init() {
            this.getProducts()
        },

        onPageChange(page) {
            this.getProducts(page)
        },

        getPlans() {
            return new Promise((resolve, reject) => {
                if (Object.keys(this.plans).length > 0) {
                    resolve()
                } else {
                    const params = {
                        SPID: this.current_spid,
                    }

                    this.$store.dispatch('api_postpay/FindPostpayMobilePlansPaginated', params)
                        .then(({ PostpayMobilePlans }) => {
                            this.plans = Array.isArray(PostpayMobilePlans) ? PostpayMobilePlans.reduce((obj, item) => {
                                obj[item.UUID] = { ...item }
                                return obj
                            }, {}) : {}

                            resolve()
                        })
                        .catch(error => {
                            reject(error)
                        })
                }
            })
        },

        getProducts(page) {
            this.loading = true

            this.getPlans()
                .then(() => {
                    const params = {
                        SPID: this.current_spid,
                        CustomerUUID: this.customer_uuid,

                        'SearchOptions.PageNumber': page ? page : this.pagination.page,
                        'SearchOptions.PageSize':   this.pagination.limit,
                    }

                    this.$store.dispatch('api_postpay/FindPostpayProductsPaginated', params)
                        .then(({ PostpayProducts, PageInfo }) => {
                            this.products = PostpayProducts

                            this.pagination.page = PageInfo.PageNumber
                            this.pagination.total = PageInfo.TotalItemCount

                            this.errors.show = false
                            this.errors.message = ''

                            this.loading = false
                        })
                        .catch(error => {
                            if (error && error.response && error.response.status == 404) {
                                // this.errors.show = true
                                // this.errors.message = "No postpay products found"
                            } else {
                                this.errors.show = true
                                this.errors.message = errMessage(error)
                            }

                            this.products = []

                            this.loading = false
                        })
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.plans = {}

                    this.loading = false
                })
        },

        handleWindowResize() {
            const scrollbar_width = window.innerWidth - document.documentElement.clientWidth
            const window_width = document.body.clientWidth + scrollbar_width

            this.large_tablet_mode = window_width <= this.$large_tablet_size
                                  && window_width > this.$tablet_size
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            '$tablet_size',
        ]),

        customer_uuid() {
            return this.$route.params.uuid
        },

        rows() {
            return Array.isArray(this.products)
                ? this.products.map(product => ({
                    UUID: product.UUID,

                    IMSI: product.IMSI,
                    MSISDN: product.MSISDN,
                    State: product.State,
                    SIMID: product.SIMID,
                    CustomerUUID: product.CustomerUUID,
                    PortingAction: product.PortingAction,
                    PortingState: product.PortingState,

                    Plan: this.plans[product.ProductAvailabilityUUID]
                        ? this.plans[product.ProductAvailabilityUUID]
                        : null,
                }))
                : []
        },

        $large_tablet_size() {
            return 1280
        },

        products_list_class() {
            return this.large_tablet_mode ? 'col-12' : 'col-8'
        },

        adding_banner_class() {
            return this.large_tablet_mode ? 'col-12' : 'col-4'
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">

$large-tablet-size: 1280px;

.page-customer-products-postpay-mobile {
    .app-error {
        margin-bottom: 24px;
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $large-tablet-size) {
    .page-customer-products-postpay-mobile {
        .app-adding-banner-service {
            margin-top: 30px;
        }

        .app-adding-banner {
            flex-direction: row;
            justify-content: space-between;
            padding: 24px;

            .banner-body {
                flex-grow: 1;
                align-items: flex-start;

                .banner-description {
                    text-align: left;
                    max-width: none;
                    margin-bottom: 48px;
                }

                .banner-img {
                    display: none;
                }
            }

            &>.banner-img {
                flex-shrink: 0;
                width: 47.75%;
                max-width: 310px;
                display: initial;
                margin: 0 0 0 16px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-customer-products-postpay-mobile {
        .app-pagination,
        .app-adding-banner-service {
            margin-top: 24px;
        }
    }
}
</style>