<template>
    <app-dialog-info
        class="app-dialog-product-settings-change"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Supplementary Voice and SMS Settings</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <div class="form-section">
                <div class="form-group">

                    <div class="form-controls">
                        <app-dropdown-select
                            v-model="settings.OutgoingCallerID"
                            :options="outgoing_caller_id_options"

                            key-value="value"
                            key-title="title"

                            label="CLIR (Calling Line Identification Restriction)"

                            :error="errors.fields.OutgoingCallerID"

                            @change="errors.fields.OutgoingCallerID = null"
                        />
                    </div>

                    <div class="form-controls">
                        <app-switch v-model="settings.IncomingCallerID">CLIP (Calling Line Identification Presentation)</app-switch>
                    </div>

                    <div class="form-controls">
                        <app-switch v-model="settings.OCSRoamingBarred">Block roaming</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSInCallsWhileRoamingBarred">Block Inbound Calls while Roaming</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSOutCallsWhileRoamingBarred">Block Outbound Calls while Roaming</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSOOBWhileRoamingBarred">Block Out-of-Bundle calls while Roaming</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSBlockPremiumCalls">Block Premium Calls</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSBlockPremiumSMS">Block Premium SMS</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSOutgoingIDDBarred">Outgoing International Barred</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.OCSOutgoingTextBarred">Outgoing Text Barred</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.AllowSMSRecieve">Customer allows Incoming SMS</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.AllowSMSSend">Customer allows Outgoing SMS</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.AllowIncomingCalls">Customer allows Incoming calls</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.AllowOutgoingCalls">Customer allows Outgoing calls</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.BarIncomingCalls">Operator Barring of Incoming calls+SMS</app-switch>
                    </div>
                    <div class="form-controls">
                        <app-switch v-model="settings.BarOutgoingCalls">Operator Barring of Outgoing calls+SMS</app-switch>
                    </div>

                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <app-switch v-model="settings.AllowCallHold">Allow call hold</app-switch>
                    </div>

                    <div class="form-controls">
                        <app-switch v-model="settings.AllowConferencing">3 Way Conferencing</app-switch>
                    </div>

                    <div class="form-controls">
                        <div class="form-sub-controls" v-if="settings.CallWaiting">
                            <div class="form-line-header">Call Waiting</div>
                            <div class="form-line-controls">
                                <app-checkbox v-model="settings.CallWaitingAvailable">Call waiting Available</app-checkbox>
                                <app-checkbox v-model="settings.CallWaitingEnabled">Call waiting Enabled</app-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="form-controls">

                        <div class="form-sub-controls" vv-if="settings.CallForwarding">
                            <div class="with-divider">
                                <div>Call Forward Unconditional</div>
                                <!-- <app-switch :disabled="true" v-model="settings.CallForwardUnconditional">Call Forward Unconditional</app-switch> -->

                                <div class="form-sub-controls" v-if="settings.CallForwarding && settings.CallForwardUnconditional">
                                    <div class="form-line-controls">
                                        <app-checkbox v-model="settings.CFUAvailable">CFU Available</app-checkbox>
                                        <app-checkbox v-model="settings.CFUActive">CFU Active</app-checkbox>
                                    </div>

                                    <app-input v-if="settings.CFUActive" v-model="settings.CFUDestination" label="CFU Destination"></app-input>
                                </div>
                            </div>

                            <div class="with-divider">
                                <div>Call Forward on Busy</div>
                                <!-- <app-switch :disabled="true" v-model="settings.CallForwardOnBusy">Call Forward on Busy</app-switch> -->

                                <div class="form-sub-controls" v-if="settings.CallForwarding && settings.CallForwardOnBusy">
                                    <div class="form-line-controls">
                                        <app-checkbox v-model="settings.CFBAvailable">CFB Available</app-checkbox>
                                        <app-checkbox v-model="settings.CFBActive">CFB Active</app-checkbox>
                                    </div>

                                    <app-input v-if="settings.CFBActive" v-model="settings.CFBDestination" label="CFB Destination"></app-input>
                                </div>
                            </div>

                            <div class="with-divider">
                                <div>Call Forward on No Reply</div>
                                <!-- <app-switch :disabled="true" v-model="settings.CallForwardOnNoReply">Call Forward on No Reply</app-switch> -->

                                <div class="form-sub-controls" v-if="settings.CallForwarding && settings.CallForwardOnNoReply">
                                    <div class="form-line-controls">
                                        <app-checkbox v-model="settings.CFNRYAvailable">CFNR Available</app-checkbox>
                                        <app-checkbox v-model="settings.CFNRYActive">CFNR Active</app-checkbox>
                                    </div>

                                    <template v-if="settings.CFNRYActive">
                                        <app-input v-model="settings.CFNRYRingtime" label="CFNR Ring time (seconds)"></app-input>

                                        <app-input v-model="settings.CFNRYDestination" label="CFNR Destination"></app-input>
                                    </template>
                                </div>
                            </div>

                            <div class="with-divider">
                                <div>Call Forward on Not Reachable</div>
                                <!-- <app-switch v-model="settings.CallForwardOnNotReachable">Call Forward on Not Reachable</app-switch> -->

                                <div class="form-sub-controls" v-if="settings.CallForwarding && settings.CallForwardOnNotReachable">
                                    <div class="form-line-controls">
                                        <app-checkbox v-model="settings.CFNRCAvailable">CFNRC Available</app-checkbox>
                                        <app-checkbox v-model="settings.CFNRCActive">CFNRC Active</app-checkbox>
                                    </div>

                                    <app-input v-if="settings.CFNRCActive" v-model="settings.CFNRCDestination" label="CFNRC Destination"></app-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="actions">
                <button class="btn btn-primary btn-confirm" @click="confirm" :disabled="!is_changed">Confirm</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appSwitch from '@/components/app-switch'
import appInput from '@/components/app-input'
import appCheckbox from '@/components/app-checkbox'
import appDropdownSelect from '@/components/app-dropdown-select'

import appDialogInfo from '@/components/app-dialog-info'

import errMessage from '@/helpers/errMessage'

const DEFAULT_SETTINGS = {
    CallWaiting: true,
        CallWaitingAvailable: true,
        CallWaitingEnabled: true,

    AllowCallHold: true,

    CallForwarding: true,
        CallForwardUnconditional: true,
            CFUAvailable: true,
            CFUActive: false,
            CFUDestination: '',
        CallForwardOnBusy: true,
            CFBAvailable: true,
            CFBActive: false,
            CFBDestination: '',
        CallForwardOnNoReply: true,
            CFNRYAvailable: true,
            CFNRYActive: false,
            CFNRYRingtime: 30,
            CFNRYDestination: '',
        CallForwardOnNotReachable: true,
            CFNRCAvailable: true,
            CFNRCActive: false,
            CFNRCDestination: '',

    BarIncomingCalls: false,
    BarOutgoingCalls: false,
    AllowOutgoingCalls: true,
    AllowIncomingCalls: true,

    AllowConferencing: true,

    OCSBlockPremiumCalls: false,
    OCSPremiumCallsBarred: [],
    OCSBlockPremiumSMS: false,
    OCSPremiumSMSBarred: [],
    OCSInCallsWhileRoamingBarred: false,
    OCSOOBWhileRoamingBarred: false,
    OCSOutCallsWhileRoamingBarred: false,
    OCSOutgoingIDDBarred: false,
    OCSOutgoingTextBarred: false,
    OCSRoamingBarred: false,

    AllowSMSRecieve: true,
    AllowSMSSend: true,

    OutgoingCallerID: 'CLIR_NONE',
    IncomingCallerID: true,
}

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 1110 },

        product: { required: true, },
    },

    components: {
        appLoader,
        appError,

        appSwitch,
        appInput,
        appCheckbox,
        appDropdownSelect,

        appDialogInfo,
    },

    data() {
        return {
            settings:          JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
            original_settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getVoiceSettings()
        },

        getVoiceSettings() {
            this.loading = true
console.log('product', this.product)
            const params = {
                SPID: this.current_spid,
                CustomerUUID: this.product.CustomerUUID,
                UUID: this.product.UUID,
            }

            this.$store.dispatch('api_postpay/GetVoiceSettings', params)
                .then(({ VoiceSettings }) => {
                    this.settings = {
                        ...this.settings,

                        ...VoiceSettings,
                    }
                    this.settings.CallForwarding = true
                    this.settings.CallForwardUnconditional = true
                    this.settings.CallForwardOnBusy = true
                    this.settings.CallForwardOnNoReply = true
                    this.settings.CallForwardOnNotReachable = true
                    this.original_settings = JSON.parse(JSON.stringify(this.settings))

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },

        confirm() {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                UUID: this.product.UUID,

                ProductAvailabilityUUID: this.product.Plan.ProductAvailabilityUUID,

                CustomerUUID: this.$route.params.uuid,

                VoiceSettings: {
                    ...this.settings,

                    CFNRYRingtime: +this.settings.CFNRYRingtime,
                },
            }

            this.$store.dispatch('api_postpay/UpdateVoiceSettings', params)
                .then(() => {
                    this.errors.show = false
                    this.errors.message = ''

                    this.$emit('confirm')
                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },

        is_changed() {
            let is_changed = false

            for (const key in this.settings) {
                is_changed = this.settings[key] != this.original_settings[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },

        outgoing_caller_id_options() {
            return [
                {
                    value: 'CLIR_NONE',
                    title: 'CLIR none',
                },
                {
                    value: 'HIDDEN',
                    title: 'Hidden',
                },
                {
                    value: 'VISIBLE',
                    title: 'Visible',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
$gutter: 30px;

.app-dialog.app-dialog-info.app-dialog-product-settings-change {
    .app-dialog-body {
        align-items: stretch;

        h2 {
            &.heading {
                width: 100%;
            }
        }

        .app-error {
            margin-bottom: 24px;
        }

        .form-section {
            display: flex;

            margin: 0 #{ -$gutter } 40px;

            .form-group {
                flex: 1 0 0%;

                padding: 0 $gutter;

                .form-controls {
                    margin-bottom: 30px;

                    .app-switch {
                        width: 100%;
                        justify-content: space-between;
                    }

                    .app-input {
                        width: auto;
                        flex-grow: 1;
                        min-width: 180px;
                    }

                    & > .form-sub-controls {
                        padding: 16px;

                        background-color: var(--color-collapse-bg);

                        border-radius: $border-radius-primary;

                        .with-divider {
                            position: relative;

                            margin-bottom: 16px;
                            padding-bottom: 16px;

                            .form-sub-controls {
                                & > * {
                                    margin-bottom: 16px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                            &::after {
                                position: absolute;

                                bottom: 0;
                                left: 0;
                                right: 0;

                                display: block;

                                height: 1px;

                                background-color: var(--color-divider);

                                content: '';
                            }

                            &:last-child {
                                margin-bottom: 0;
                                padding-bottom: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .form-sub-controls {
                        margin-top: 16px;

                        .app-switch {
                            .label {
                                font-size: 18px;
                            }
                        }
                    }

                    .form-line-controls {
                        display: flex;

                        .app-checkbox {
                            margin: 0 48px 0 0;

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                    .form-line-header {
                        display: flex;
                        margin-bottom: 10px;
                        font-size: 1.2em;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .actions {
            display: flex;
            justify-content: center;

            .btn-confirm {
                max-width: 210px;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    /*
        .app-dialog.app-dialog-info.app-dialog-product-settings-change {
            .app-dialog-body {}
        }
    */
}

@media (max-width: $mobile-size) {
    /*
        .app-dialog.app-dialog-info.app-dialog-product-settings-change {
            .app-dialog-body {}
        }
    */
}
</style>