<template>
    <div class="page-customer-products-prepay-mobile">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="row">
            <div class="col-tab-12" :class="[ products_list_class ]">
                <app-error v-model="errors.show" :message="errors.message" />

                <div class="products-list" :class="{ 'has-no-one-product': has_no_one_product }">
                    <h2 class="heading">PrePay Mobile Products</h2>

                    <div class="product with-plan" v-for="(product, index) in formatted_products" :key="index">
                        <div class="header">
                            <h3 class="heading">{{ product.Name }}</h3>

                            <div class="actions">
                                <app-actions
                                    :actions="actions"
                                    :disabled="isActionsDisabled(product)"

                                    @number-port="numberPort(product)"
                                />
                            </div>
                        </div>

                        <div class="product-body">
                            <div class="main-info">
                                <img src="./mobile-product.png">

                                <div class="details-list">
                                    <div class="detail">
                                        <span class="title">MSISDN:</span>
                                        <span class="value">{{ product.MSISDN }}</span>
                                    </div>

                                    <div class="detail">
                                        <span class="title">State:</span>
                                        <span class="value">{{ product.State }}</span>
                                    </div>

                                    <div class="detail">
                                        <span class="title">UUID:</span>
                                        <span class="value">{{ product.UUID }}</span>
                                    </div>

                                    <div class="detail">
                                        <span class="title">Created at:</span>
                                        <span class="value">{{ product.CreatedAtNanos }}</span>
                                    </div>

                                    <div class="detail">
                                        <span class="title">Expires at:</span>
                                        <span class="value">{{ product.ExpiresAtNanos }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="plan">
                                <h4 class="heading">Plan:</h4>

                                <div class="content">
                                    <div class="column">
                                        <div class="title">Description</div>
                                        <div class="value">{{ product.Description }}</div>
                                    </div>

                                    <div class="column">
                                        <div class="title">Price</div>
                                        <div class="value">${{ product.RetailPriceCents }} / month</div>
                                    </div>
                                </div>

                                <h4 class="heading">Conditions:</h4>

                                <div class="content conditions">
                                    <div class="column">
                                        <div class="value"><span>Bonus credits:</span> ${{ product.CreditCents }}</div>
                                        <div class="value"><span>Minutes:</span> {{ product.CreditVoiceMinutes }}</div>
                                        <div class="value"><span>SMS:</span> {{ product.CreditSMS }}</div>
                                        <div class="value"><span>MMS:</span> {{ product.CreditMMS }}</div>
                                        <div class="value"><span>MB:</span> {{ product.CreditDataMB }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-tab-12" :class="[ adding_banner_class ]">
                <app-adding-banner-service service="PrePay Mobile" />
            </div>
        </div>

        <app-dialog-product-number-port
            v-if="dialogs.product.number_port.show"
            v-model="dialogs.product.number_port.show"

            :product="dialogs.product.number_port.product"

            product-type="prepaymobile"

            @confirm="onDialogProductNumberPortConfirm"
            @close="onDialogProductNumberPortClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appActions from '@/components/app-actions'
import appAddingBannerService from '@/components/app-adding-banner-service'

import appDialogProductNumberPort from '../components/app-dialog-product-number-port'

import formatDateNano from '@/helpers/format-date-nano'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appActions,
        appAddingBannerService,

        appDialogProductNumberPort,
    },

    data() {
        return {
            products: [],

            loading: false,

            dialogs: {
                product: {
                    number_port: {
                        show: false,
                        product: null,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },

            large_tablet_mode: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        numberPort(product) {
            this.dialogs.product.number_port.show = true
            this.dialogs.product.number_port.product = product
        },
        onDialogProductNumberPortConfirm() {
            this.getProducts()

            this.dialogs.product.number_port.show = false
            this.dialogs.product.number_port.product = null
        },
        onDialogProductNumberPortClose() {
            this.dialogs.product.number_port.show = false
            this.dialogs.product.number_port.product = null
        },

        isActionsDisabled(product) {
            return !(product.Status && product.Status.toLowerCase() == 'active')
        },

        init() {
            this.getProducts()
        },

        getProducts() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                CustomerUUID: this.customer_uuid,
            }

            this.$store.dispatch('api_prepay/GetPrepayProductsPaginated', params)
                .then(({ PrepayProducts }) => {
                    this.products = PrepayProducts

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    if (error && error.response && error.response.status == 404) {
                        // this.errors.show = true
                        // this.errors.message = "No Pre-Pay products found"
                    } else {
                        this.errors.show = true
                        this.errors.message = errMessage(error)
                    }

                    this.products = []

                    this.loading = false
                })
        },

        handleWindowResize() {
            const scrollbar_width = window.innerWidth - document.documentElement.clientWidth
            const window_width = document.body.clientWidth + scrollbar_width

            this.large_tablet_mode = window_width <= this.$large_tablet_size
                                  && window_width > this.$tablet_size
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            '$tablet_size',
        ]),

        customer_uuid() {
            return this.$route.params.uuid
        },

        formatted_products() {
            const products = []

            if (Array.isArray(this.products)) {
                for (let i = 0, len = this.products.length; i < len; i++) {
                    let product = {
                        MSISDN: this.products[i].MSISDN,
                        Status: this.products[i].Status,
                    }

                    for (let j = 0, len = this.products[i].AddOns.length; j < len; j++) {
                        product = {
                            ...product,

                            Name: this.products[i].AddOns[j].Name ? this.products[i].AddOns[j].Name : '?',

                            State: this.products[i].AddOns[j].State,

                            UUID: this.products[i].AddOns[j].UUID,

                            CreatedAtNanos: this.products[i].AddOns[j].CreatedAtNanos ? formatDateNano(this.products[i].AddOns[j].CreatedAtNanos) : '?',
                            ExpiresAtNanos: this.products[i].AddOns[j].ExpiresAtNanos ? formatDateNano(this.products[i].AddOns[j].ExpiresAtNanos) : '?',

                            Description: this.products[i].AddOns[j].Description ? this.products[i].AddOns[j].Description : '?',

                            RetailPriceCents: this.products[i].AddOns[j].RetailPriceCents % 100 == 0 ? this.products[i].AddOns[j].RetailPriceCents / 100 : (this.products[i].AddOns[j].RetailPriceCents / 100).toFixed(2),

                            CreditCents: this.products[i].AddOns[j].CreditCents % 100 == 0 ? this.products[i].AddOns[j].CreditCents / 100 : (this.products[i].AddOns[j].CreditCents / 100).toFixed(2),
                            CreditVoiceMinutes: this.products[i].AddOns[j].CreditVoiceMinutes,
                            CreditSMS: this.products[i].AddOns[j].CreditSMS,
                            CreditMMS: this.products[i].AddOns[j].CreditMMS,
                            CreditDataMB: this.products[i].AddOns[j].CreditDataMB,
                        }

                        products.push(product)
                    }
                }
            }

            return products
        },

        $large_tablet_size() {
            return 1280
        },

        products_list_class() {
            return this.large_tablet_mode ? 'col-12' : 'col-8'
        },

        adding_banner_class() {
            return this.large_tablet_mode ? 'col-12' : 'col-4'
        },

        has_no_one_product() {
            return Array.isArray(this.products)
                ? this.products.length == 0
                : Object.keys(this.products).length == 0
        },

        actions() {
            return [
                { action: 'number-port', title: 'Create number port' },
            ]
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">

$large-tablet-size: 1280px;
$small-tablet-size: 767px;

.page-customer-products-prepay-mobile {
    .app-error {
        margin-bottom: 24px;
    }

    .products-list {
        position: relative;

        width: 100%;

        padding: 24px 24px 0;

        border-radius: $border-radius-secondary;
        background-color: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 8px;
            }
        }

        .product {
            $picture-width: 130px;

            position: relative;

            padding: 16px 0;

            .header {
                display: flex;
                flex-wrap: wrap;

                margin-bottom: 24px;

                .actions {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;

                    margin-left: auto;

                    .btn {
                        display: flex;

                        width: auto;
                        height: 24px;

                        font-size: 18px;

                        &.action-cancel {
                            &::before {
                                width: 24px;
                                height: 24px;

                                font-size: 18px;
                                line-height: 24px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .product-body {
                display: flex;

                .main-info {
                    display: flex;

                    width: 100%;

                    font-size: 16px;
                    line-height: 24px;

                    img {
                        display: block;

                        width: $picture-width;
                        height: $picture-width;

                        object-fit: cover;

                        border-radius: $border-radius-primary;
                    }

                    .details-list {
                        width: calc(100% - #{ $picture-width });

                        padding: 5px 24px;

                        .detail {
                            display: flex;
                            flex-wrap: wrap;

                            margin-bottom: 8px;

                            .title {
                                margin-right: 8px;
                            }

                            .value {
                                color: var(--color-table-secondary);
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .plan {
                    width: 100%;

                    padding-left: 24px;

                    h4 {
                        &.heading {
                            margin-bottom: 16px;

                            font-size: 18px;
                            font-weight: bold;
                            line-height: 24px;
                        }
                    }

                    .content {
                        display: flex;
                        justify-content: space-between;

                        width: 100%;

                        margin-bottom: 16px;
                        padding: 8px 16px;

                        background-color: var(--color-collapse-bg);

                        border-radius: $border-radius-primary;

                        .column {
                            margin-right: 24px;

                            .title {
                                margin-bottom: 2px;
                            }

                            .value {
                                color: var(--color-table-secondary);

                                span {
                                    color: var(--color-text-default);
                                }
                            }

                            &:last-child {
                                flex-shrink: 0;
                                margin-right: 0;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.with-plan {
                .product-body {
                    .main-info {
                        max-width: calc(min(45%, 390px));

                        border-right: 1px solid var(--color-input-border);
                    }
                }
            }

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;

                display: block;
                height: 1px;

                content: '';

                background-color: var(--color-divider);
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }

        &.has-no-one-product {
            padding-bottom: 16px;
        }
    }
}

@media (max-width: $large-tablet-size) {
    .page-customer-products-prepay-mobile {
        .app-adding-banner-service {
            margin-top: 30px;
        }

        .app-adding-banner {
            flex-direction: row;
            justify-content: space-between;
            padding: 24px;

            .banner-body {
                flex-grow: 1;
                align-items: flex-start;

                .banner-description {
                    text-align: left;
                    max-width: none;
                    margin-bottom: 48px;
                }

                .banner-img {
                    display: none;
                }
            }

            &>.banner-img {
                flex-shrink: 0;
                width: 47.75%;
                max-width: 310px;
                display: initial;
                margin: 0 0 0 16px;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-customer-products-prepay-mobile {
        .products-list {
            padding: 24px 16px 0;

            .product {
                $picture-width: 120px;

                .header {
                    margin-bottom: 16px;
                }

                .product-body {
                    .main-info {
                        font-size: 14px;

                        img {
                            width: $picture-width;
                            height: $picture-width;
                        }

                        .details-list {
                            width: calc(100% - #{ $picture-width });

                            padding: 0 16px;
                        }
                    }

                    .plan {
                        padding-left: 16px;

                        h4 {
                            &.heading {
                                margin-bottom: 12px;
                            }
                        }

                        .content {
                            margin-bottom: 12px;
                            padding: 8px 8px 4px;

                            .column {
                                margin-right: 10px;

                                .title {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $small-tablet-size) {
    .page-customer-products-prepay-mobile {
        .products-list {
            .product {
                $picture-width: 108px;

                padding: 8px 0;

                .header {
                    h3 {
                        &.heading {
                            font-size: 18px;
                        }
                    }

                    .actions {
                        .btn {
                            font-size: 16px;

                            span {
                                display: none;
                            }
                        }
                    }
                }

                .product-body {
                    flex-direction: column;

                    .main-info {
                        position: relative;

                        img {
                            width: $picture-width;
                            height: $picture-width;
                        }

                        .details-list {
                            width: calc(100% - #{ $picture-width });

                            .detail {
                                margin-bottom: 4px;
                            }
                        }
                    }

                    .plan {
                        padding: 8px 0 0 0;

                        h4 {
                            &.heading {
                                margin-bottom: 8px;

                                font-size: 16px;
                            }
                        }

                        .content {
                            flex-direction: column;

                            margin-bottom: 8px;
                            padding: 8px;

                            .column {
                                margin: 0 0 8px 0;

                                &:last-child {
                                    display: flex;

                                    margin-bottom: 0;

                                    .title {
                                        margin-right: 8px;
                                    }
                                }
                            }

                            &.conditions {
                                .column {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }

                &.with-plan {
                    .product-body {
                        .main-info {
                            max-width: 100%;

                            padding-bottom: 8px;

                            border-right: none;

                            &::after {
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;

                                display: block;
                                height: 1px;

                                content: '';

                                background-color: var(--color-input-border);
                            }
                        }
                    }
                }

                &::after {
                    background-color: var(--color-input-border);
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-customer-products-prepay-mobile {
        .products-list {
            padding: 16px 8px 0;

            &.has-no-one-product {
                padding-bottom: 8px;
            }
        }

        .app-adding-banner-service {
            margin-top: 24px;
        }
    }
}
</style>